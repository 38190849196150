// VARIABLES
$app-min-width: 0;
$transition-regular: 0.3s all ease-in-out;
$transition-bounce: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.45);
$padding-content: 60px;
$padding-content-sm: 30px;
$shadow-card: 0 10px 20px
  rgba(
    $color: $dark,
    $alpha: 0.15,
  );
$shadow: 0 4px 8px;
$shadow-lg: 0 10px 20px;
$shadow-xl: 0 20px 40px;
$shadow-color: rgba(
  $color: $dark,
  $alpha: 0.15,
);
$shadow-input: 0 8px 20px
  rgba(
    $color: $dark,
    $alpha: 0.05,
  );
$sidebar-width: 340px;
$p-10: 10px;
$p-16: 16px;
$p-24: 24px;
$p-32: 32px;
$p-48: 48px;
$p-64: 64px;
$table-hover: rgba($primary, 0.05);
$avatar-sm: 60px;
$avatar-lg: 120px;
$avatar-md: 80px;
// BUTTON
// Variables
$btn-radius: 2px;
$btn-height: 60px;
$btn-height-lg: 72px;
$btn-height-sm: 48px;
$btn-height-xs: 32px;
$btn-font-size: 16px;
// MIXIN
@mixin center-vh() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin center-v() {
  display: flex;
  align-items: center;
}

@mixin center-h() {
  display: flex;
  justify-content: center;
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  border-radius: $size / 3;
  overflow: hidden;
  background: $light;
  display: block;
  border: 1px solid $border;
  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}

@mixin btn-shadow($color) {
  box-shadow: 0 4px 16px rgba($color: $color, $alpha: 0.25);
  transition: $transition-regular;
  &:hover {
    box-shadow: 0 2px 4px rgba($color: $color, $alpha: 0.1);
  }
}

@mixin shadow-icon($color, $size) {
  @if $size==lg {
    filter: drop-shadow($shadow-lg rgba($color: $color, $alpha: 0.5));
  } @else if $size==reg {
    filter: drop-shadow($shadow rgba($color: $color, $alpha: 0.5));
  }
}

@mixin box-shadow($color, $size) {
  @if $size==reg {
    box-shadow: $shadow rgba($color: $color, $alpha: 0.05);
  } @else if $size==lg {
    box-shadow: $shadow-lg rgba($color: $color, $alpha: 0.05);
  } @else if $size==xl {
    box-shadow: $shadow-xl rgba($color: $color, $alpha: 0.1);
  }
}

// GENERAL CLASSES
.hidden {
  display: none;
}

.spacer {
  height: 1px;
  text-align: center;
  display: block;
  &--xs {
    margin: $p-10 0;
  }
  &--sm {
    margin: $p-10 0;
  }
  &--md {
    margin: $p-32 0;
  }
  &--lg {
    margin: $p-64 0;
  }
  &--border {
    // background-color: rgba($dark, 0.15);
    background-color: $border;
  }
  &--border-sm {
    height: 1px;
    background-color: $border;
  }
}

.avatar {
  @include avatar($avatar-md);
  &--sm {
    @include avatar($avatar-sm);
  }
  &--lg {
    @include avatar($avatar-lg);
  }
  &--round {
    border-radius: 50%;
  }
}

.full {
  &--height {
    min-height: 100vh;
    height: 100%;
  }
  &--width {
    min-width: 100vw;
  }
}

.hidden {
  display: none !important;
}

.bg {
  &-primary-gradient {
    background: url("../assets/images/bg.jpg") no-repeat top left / cover;
  }
}

// LIST - NOTIFICATION
@mixin list-notification-type($color) {
  .icon {
    color: $white;
    background: rgba($color: $color, $alpha: 1);
  }
  .type {
    // color: $color
  }
}
