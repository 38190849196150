/*****************
TYPOGRAPHY
*****************/

html,
body {
  font-size: 14px;
  line-height: 1.5;
  color: rgba($color: $dark, $alpha: 0.85);
  font-family: var(--font-family-body);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-hd);
}

.display {
  &-1 {
    font-size: 4rem;
  }
  &-2 {
    font-size: 3.5rem;
  }
  &-3 {
    font-size: 2.5rem;
  }
  &-4 {
    font-size: 2rem;
  }
}

h1,
.h1 {
  // @extend h1;
  // font-size: 1.41094rem;
  font-size: 1.75rem;
  font-weight: 700;
}

h2,
.h2 {
  @extend h2;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 1.25em;
}

h3,
.h3 {
  @extend h3;
  // font-size: 1.75em;
  font-size: 1.14844rem;
  font-weight: 600;
}

h4,
.h4 {
  @extend h4;
  font-size: 0.98438rem;
  font-weight: 600;
}

h5,
.h5 {
  font-size: 0.875rem;
}

h6,
.h6 {
  font-size: 0.76563rem;
}

p {
  &.lead {
    font-size: 1.35rem;
    line-height: 1.45em;
  }
}

small,
.small {
  font-size: 0.8rem;
}

.text {
  &-strong {
    font-weight: 900;
  }
  &-semibold {
    font-weight: 600;
  }
  &-bold {
    font-weight: 700;
  }
  &-faded {
    opacity: 0.5;
  }
  &-light {
    font-weight: 300;
  }
}

.psuedo-border {
  position: relative;
  margin-bottom: 40px;
  &:before {
    content: "";
    display: inline-block;
    width: 10%;
    height: 4px;
    border-radius: 4px;
    background: rgba($color: $primary, $alpha: 1);
    position: absolute;
    bottom: -10px;
    left: 0;
    box-shadow: 0 4px 10px rgba($color: $primary, $alpha: 0.25);
  }
}

.highlight {
  padding: 0 8px;
  border-radius: 2px;
  &--primary {
    background: $primary;
    color: $white;
  }
  &--secondary {
    background: $secondary;
    color: $white;
  }
}

.underline {
  position: relative;
  // z-index: 2;
  &::before {
    content: "";
    height: 8px;
    width: 100%;
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    // z-index: 0;
    // border-radius: 1px;
  }
  &--primary {
    &::before {
      background: $primary;
    }
  }
  &--secondary {
    &::before {
      background: $secondary;
    }
  }
}
